/* Scoped to EventDetails */
.event-details {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #FFFFFF;
    text-align: center;
}

.event-details-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Stats Card */
.stats-card {
    background-color: #1C1C1E;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    color: #FFFFFF;
}

/* Possession Section */
.possession-container {
    margin-bottom: 30px;
}

.possession-label {
    font-size: 1rem; /* Match the other stat labels */
    font-weight: normal; /* Override h2 boldness */
    margin-bottom: 10px;
    text-align: center;
    color: #A8A8A8; /* Same gray as other stat labels */
    line-height: 1.5; /* Ensure readability with consistent spacing */
}

/* Possession Row */
.possession-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.possession-bar {
    display: flex;
    width: 100%;
    height: 30px;
    background-color: #2C2C2E;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
}

.bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #FFFFFF;
    font-size: 0.9rem;
    font-weight: bold;
    position: relative;
    z-index: 1;
}

.bar.home {
    background-color: #007AFF;
}

.bar.away {
    background-color: #FF9500;
}

.possession-percentage {
    position: absolute;
    z-index: 2;
    font-size: 0.9rem;
    font-weight: bold;
    color: #FFFFFF;
}


/* Stat Rows */
.stats-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.stat-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #2C2C2E;
}

.stat-row:last-child {
    border-bottom: none;
}

.stat-name {
    flex: 2;
    text-align: center;
    font-size: 1rem;
    color: #A8A8A8;
}

/* Highlight Winner */
.team-value {
    flex: 0 0 50px; /* Smaller width for compact design */
    height: 50px; /* Make it more circular */
    line-height: 50px; /* Center text vertically */
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 50%; /* Circular appearance */
    padding: 0;
    color: #FFFFFF;
    background-color: #2C2C2E; /* Default background */
    transition: all 0.3s ease-in-out;
}

.team-value.highlight {
    background-color: #007AFF; /* Blue for home winner */
    color: #FFFFFF;
    box-shadow: 0 0 8px rgba(0, 122, 255, 0.8);
}

.team-value.away.highlight {
    background-color: #FF9500; /* Orange for away winner */
    color: #FFFFFF;
    box-shadow: 0 0 8px rgba(255, 149, 0, 0.8);
}

/* No highlight for the losing team */
.team-value:not(.highlight) {
    background-color: transparent; /* No background for losers */
    color: #FFFFFF; /* Default white text */
    box-shadow: none;
}

/* Responsiveness */
@media (max-width: 768px) {
    .stat-name {
        font-size: 0.9rem;
    }

    .team-value {
        flex: 0 0 40px; /* Smaller for mobile screens */
        height: 40px;
        line-height: 40px;
        font-size: 0.9rem;
    }

    .possession-bar {
        height: 25px;
    }

    .possession-percentage {
        font-size: 0.8rem;
    }
}

/* Graceful Handling of Missing Stats */
.no-details {
    background-color: #1C1C1E;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 20px;
}

.no-details p {
    font-size: 1.1rem;
    color: #A8A8A8;
    margin: 10px 0;
}


.centered-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    width: fit-content;
    text-align: center;
}

/* Lineup Section */
.lineup-section {
    margin-top: 20px;
    background-color: #1C1C1E;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    overflow-x: auto; /* Allow horizontal scrolling on small screens */
}

.team-lineup {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.team-column {
    flex: 1;
    margin: 0 10px;
}

.lineup-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    min-width: 600px; /* Ensure table doesn't shrink too much */
}

.lineup-table th,
.lineup-table td {
    padding: 10px;
    text-align: left;
    color: #FFFFFF;
    border-bottom: 1px solid #2C2C2E;
}

.lineup-table th {
    background-color: #2C2C2E;
    font-weight: bold;
}

.player-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
}

.player-card {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #2C2C2E;
    border-radius: 8px;
}

.player-info {
    text-align: left;
    color: #FFFFFF;
}

.player-info h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}

.player-info p {
    margin: 2px 0;
    font-size: 0.9rem;
    color: #A8A8A8;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .lineup-table {
        min-width: 100%; /* Allow table to fit within the screen */
    }

    .lineup-table th,
    .lineup-table td {
        font-size: 0.8rem; /* Smaller font size for mobile */
        padding: 8px;
    }

    .player-thumb {
        width: 25px;
        height: 25px;
    }
}

.team-logo {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
}

.team-container {
    margin-bottom: 30px;
    background-color: #1C1C1E;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.lineups-container {
    display: flex;
    gap: 24px;
    margin-top: 24px;
}

.lineup-team {
    flex: 1;
    background-color: #1C1C1E;
    border-radius: 16px;
    overflow: hidden;
}

.lineup-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
    background-color: #2C2C2E;
}

.lineup-header .team-badge {
    width: 32px;
    height: 32px;
}

.lineup-header h2 {
    margin: 0;
    font-size: 1.2rem;
    color: #FFFFFF;
}

.lineup-players {
    padding: 16px;
}

.player-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.player-row:last-child {
    border-bottom: none;
}

.player-primary {
    display: flex;
    align-items: center;
    gap: 12px;
}

.player-thumb {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.1);
}

.player-name {
    font-size: 0.95rem;
    color: #FFFFFF;
    font-weight: 500;
}

.player-secondary {
    display: flex;
    align-items: center;
    gap: 16px;
}

.player-position {
    color: #A8A8A8;
    font-size: 0.85rem;
}

.player-number {
    background: rgba(0, 122, 255, 0.15);
    color: #007AFF;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 600;
    min-width: 24px;
    text-align: center;
}

@media (max-width: 768px) {
    .lineups-container {
        flex-direction: column;
    }

    .player-row {
        padding: 10px;
    }

    .player-thumb {
        width: 32px;
        height: 32px;
    }

    .player-name {
        font-size: 0.9rem;
    }

    .player-position {
        font-size: 0.8rem;
    }
}

.section-title {
    color: #FFFFFF;
    font-size: 1.5rem;
    margin: 32px 0 16px;
    padding: 0 16px;
}

